import React from 'react';
import PropTypes from 'prop-types';

function StackOverflowIcon({
  color,
  size,
  marginRight,
  marginBottom,
  marginTop,
  marginLeft,
}) {
  const sizeString = `${size}px`;
  const svg = (
    <svg
      width={sizeString}
      height={sizeString}
      viewBox="0 0 25 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
      }}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.6497 2.08142L18.6497 9.08142L20.2771 7.91895L15.2771 0.918945L13.6497 2.08142ZM17.3126 11.2592L10.3126 5.25924L11.6142 3.74072L18.6142 9.74072L17.3126 11.2592ZM7.5376 9.90485L16.0376 13.9049L16.8892 12.0952L8.38919 8.09521L7.5376 9.90485ZM15.2465 16.4763L6.24646 14.4763L6.68032 12.5239L15.6803 14.5239L15.2465 16.4763ZM3.96338 15H1.96338V23H19.9634V15H17.9634V21H3.96338V15ZM6.46338 19H15.4634V17H6.46338V19Z"
        clipRule="evenodd"
      />
    </svg>
  );
  return svg;
}

StackOverflowIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
};

StackOverflowIcon.defaultProps = {
  marginRight: 0,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
};

export default StackOverflowIcon;
