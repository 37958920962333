import React from 'react';
import PropTypes from 'prop-types';

function LinkedInIcon({
  color,
  size,
  marginRight,
  marginBottom,
  marginTop,
  marginLeft,
}) {
  const sizeString = `${size}px`;
  const svg = (
    <svg
      width={sizeString}
      height={sizeString}
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
      }}
    >
      <path
        fill={color}
        d="M24,0H2C0.9,0,0,0.9,0,2v22c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V2C26,0.9,25.1,0,24,0z M7.816,22H4V9.725h3.816V22z M5.878,8.219c-1.349,0-2.25-0.956-2.222-2.138C3.628,4.844,4.529,3.915,5.906,3.915c1.379,0,2.251,0.929,2.279,2.166C8.186,7.263,7.286,8.219,5.878,8.219z M22,22h-3.815v-6.802c0-1.582-0.553-2.661-1.934-2.661c-1.053,0-1.681,0.728-1.932,1.431c-0.1,0.226-0.15,0.603-0.15,0.953V22h-3.816v-8.358c0-1.532-0.05-2.837-0.1-3.916h3.313l0.176,1.682h0.076c0.502-0.779,1.757-1.958,3.79-1.958c2.51,0,4.392,1.656,4.392,5.271V22z"
      />
    </svg>
  );
  return svg;
}

LinkedInIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
};

LinkedInIcon.defaultProps = {
  marginRight: 0,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
};

export default LinkedInIcon;
