import React from 'react';
import PropTypes from 'prop-types';

function TwitterIcon({
  color,
  size,
  marginRight,
  marginBottom,
  marginTop,
  marginLeft,
}) {
  const sizeString = `${size}px`;
  const twitterSVG = (
    <svg
      width={sizeString}
      height={sizeString}
      viewBox="0 0 28 22.934"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
      }}
    >
      <path
        fill={color}
        d="M1.85,1.131c0,0,4.175,5.608,11.859,6.003c0,0-1.046-4.086,2.807-6.343s7.06,1.027,7.06,1.027s1.977-0.33,3.676-1.344c0,0-0.57,2.053-2.472,3.169c0,0,2.053-0.292,3.22-0.862c0,0-1.166,1.876-2.801,2.928c0,0,0.71,11.826-10.597,16.187C6.761,24.92,0,20.375,0,20.375s3.904,0.811,8.366-2.383c0,0-3.574,0.177-5.349-4.081c0,0,1.217,0.33,2.586-0.076c0,0-4.31-0.608-4.563-5.704c0,0,1.546,0.71,2.51,0.684C3.549,8.815-0.533,5.894,1.85,1.131z"
      />
    </svg>
  );
  return twitterSVG;
}

TwitterIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
};

TwitterIcon.defaultProps = {
  marginRight: 0,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
};

export default TwitterIcon;
