import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from '../../../utils/Breakpoints';

const Holder = styled.div`
  border-radius: 8px;
  width: 500px;
  box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.08);
  font-family: Avenir, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  padding: 10px;
  margin-bottom: 15px;

  @media only screen and (max-width: ${breakpoints.small}) {
    width: calc(100% - 20px);
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 28px;
`;

const Description = styled.div`
  font-size: 18px;
`;

const Pill = styled.div`
  font-size: 14px;
  padding: 4px 6px;
  background-color: rgba(255,255,255,0.12);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const PillSection = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 6px;
`;

const Image = styled.img`
  width: 54px;
  height: 54px;
  background-color: red;
  margin-right: 10px;
  border-radius: 8px;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

function ProductCard({
  title, description, tags, imageURL, link,
}) {
  const tagArray = tags.split(',');
  return (
    <Holder onClick={() => {
      window.open(link, '_blank');
    }}
    >
      <TextSection>
        <TitleSection>
          <Image src={imageURL} />
          <Title>{title}</Title>
        </TitleSection>
        <Description>{description}</Description>
        <PillSection>
          {tagArray.map((tag) => <Pill key={tag}>{tag}</Pill>)}
        </PillSection>
      </TextSection>
    </Holder>
  );
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

// ProductCard.defaultProps = {
//   imageURL: undefined,
// };

export default ProductCard;
