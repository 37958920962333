import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  width: 72px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-size: 13px;
  font-family: Avenir, sans-serif;
  color: rgba(255,255,255,0.7);
  max-height: 16px;
  flex-grow: 0;
`;

const ImageHolder = styled.div`
  width: 52px;
  height: 52px;
  background-color: ${(props) => props.bgColor};
  border-radius: 12px;
  overflow: clip;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: ${(props) => `${52 - props.spacing}px`};
  height: ${(props) => `${52 - props.spacing}px`};
  object-fit: contain;
`;

function ConsultingTechnicalItem(
  {
    title, imageURL, spacing = 0, bgColor = '#f3f3f3',
  },
) {
  return (
    <Holder>
      <ImageHolder
        bgColor={bgColor}
      >
        <Image
          src={imageURL}
          spacing={spacing}
        />
      </ImageHolder>
      <Title>{title}</Title>
    </Holder>
  );
}

ConsultingTechnicalItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  spacing: PropTypes.number,
  bgColor: PropTypes.string,
};

ConsultingTechnicalItem.defaultProps = {
  spacing: 0,
  bgColor: '#f3f3f3',
};

export default ConsultingTechnicalItem;
