/* eslint-disable no-unused-vars */

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  height: ${(props) => `${props.height}px`};
  width: 100%;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: ${(props) => `${props.height}px`};
  background-color:transparent;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const extractCanvasProperties = (canvas) => {
  const { height, width } = canvas;
  const xAxis = Math.floor(height / 2);
  const yAxis = Math.floor(width / 6);
  const context = canvas.getContext('2d');
  return {
    height, width, xAxis, yAxis, context,
  };
};

let seconds = 0;
// eslint-disable-next-line no-unused-vars
let t = 0;
const unit = 150;

function SquareWave({ height }) {
  const canvasRef = useRef(null);

  const drawAxes = (context, width, pHeight, xAxis, yAxis) => {
    context.strokeStyle = '#dbdbdb';
    context.fillStyle = '#dbdbdb';
    context.lineWidth = 5;

    // Draw X and Y axes
    const topPoint = (pHeight - unit * 2) / 2;
    const bottomPoint = (unit * 2) + topPoint;
    context.moveTo(0, xAxis);
    context.lineTo(width, xAxis);
    context.moveTo(yAxis, topPoint);
    context.lineTo(yAxis, bottomPoint);

    // Draw X axis tick at PI
    context.moveTo(yAxis + Math.PI * unit, xAxis + 5);
    context.lineTo(yAxis + Math.PI * unit, xAxis - 5);
  };

  const drawCircle = (context, xAxis, yAxis) => {
    context.moveTo(yAxis + unit, xAxis);
    context.arc(yAxis, xAxis, unit, 0, 2 * Math.PI, false);
  };

  // const drawSine = (context, width, xAxis, yAxis) => {
  //   context.strokeStyle = 'rgba(255,255,255,0.8)';
  //   // Set the initial x and y, starting at 0,0 and translating to the origin on
  //   // the canvas.
  //   let x = t;
  //   let y = Math.sin(x);
  //   context.moveTo(yAxis, unit * y + xAxis);
  //
  //   // Loop to draw segments
  //   for (let i = yAxis; i <= width; i += 10) {
  //     x = t + (-yAxis + i) / unit;
  //     y = Math.sin(x);
  //     context.lineTo(i, unit * y + xAxis);
  //   }
  // };

  const drawRotatingCircleOnPoint = (context, x, y, radius, tMult) => {
    // Draw Circle
    context.moveTo(x + radius, y);
    context.arc(x, y, radius, 0, 2 * Math.PI, false);
    context.stroke();

    // Draw arrow on the circle
    const x2 = x + radius * Math.cos(t * tMult);
    const y2 = y + radius * Math.sin(t * tMult);
    context.beginPath();
    context.moveTo(x, y);
    context.lineTo(x2, y2);
    context.stroke();

    // Draw the arrow bead
    context.beginPath();
    context.arc(x2, y2, 5, 0, 2 * Math.PI, false);
    context.fill();
    context.stroke();

    return {
      xF: x2, yF: y2,
    };
  };

  const drawSineArrow = (context, width, xAxis, yAxis) => {
    context.strokeStyle = 'rgba(255,255,255,0.8)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 4;

    // Cache position of arrow on the circle
    const x = yAxis + unit * Math.cos(t);
    const y = xAxis + unit * Math.sin(t);

    // Draw the arrow line
    context.beginPath();
    context.moveTo(yAxis, xAxis);
    context.lineTo(x, y);
    context.stroke();

    // Draw the arrow bead
    context.beginPath();
    context.arc(x, y, 5, 0, 2 * Math.PI, false);
    context.fill();
    context.stroke();

    const { xF: x2, yF: y2 } = drawRotatingCircleOnPoint(context, x, y, unit * 0.33, 3);
    const { xF: x3, yF: y3 } = drawRotatingCircleOnPoint(context, x2, y2, unit * 0.2, 5);
    const { xF: x4, yF: y4 } = drawRotatingCircleOnPoint(context, x3, y3, unit * 0.143, 7);
    const { xF, yF } = drawRotatingCircleOnPoint(context, x4, y4, unit * 0.11, 9);

    const spacer = yAxis + unit * 2;

    // Draw the arrow line
    context.beginPath();
    context.strokeStyle = 'rgba(255,255,255,0.15)';
    context.moveTo(xF, yF);
    context.lineTo(spacer, yF);
    context.stroke();

    // Draw the arrow bead
    context.beginPath();
    context.strokeStyle = 'rgba(255,255,255,0.8)';
    context.arc(spacer, yF, 5, 0, 2 * Math.PI, false);
    context.fill();
    context.stroke();
    context.moveTo(spacer, yF);
    // console.log(`yAxis: ${yAxis}`);
    // console.log(`xAxis: ${xAxis}`);
    // console.log(`unit: ${unit}`);
    // console.log(`Spacer: ${spacer}`);

    // r1: 150
    // r2: 49.5
    // r3: 30

    // Loop to draw segments

    const fudge = 4.3;

    // const fudge = Math.PI / 2 * unit + yAxis;

    for (let i = spacer; i <= width; i += 10) {
      let xG = t + (-yAxis + i) / unit;
      // console.log(`xG: ${xG}`);
      // console.log(`fudge: ${fudge}`);
      xG += fudge;
      // console.log(`Combo: ${xG}`);
      const yG = Math.sin(xG)
        + Math.sin((3 * xG)) * 0.33
        + Math.sin((5 * xG)) * 0.2
        + Math.sin((7 * xG)) * 0.143
        + Math.sin((9 * xG)) * 0.11;
      context.lineTo(i, unit * yG + xAxis);
    }

    // // Draw dashed line to yAxis
    // context.beginPath();
    // const direction = (Math.cos(t) < 0) ? 1 : -1;
    // for (let i = x; direction * i < direction * yAxis - 5; i += direction * 10) {
    //   context.moveTo(i + direction * 5, y);
    //   context.li neTo(i + direction * 10, y);
    // }
    // context.stroke();

    // // Draw yAxis bead
    // context.beginPath();
    // context.arc(yAxis, y, 5, 0, 2 * Math.PI, false);
    // context.fill();
    // context.stroke();
  };

  const drawSquareWave = (canvas) => {
    const {
      context, width, height: rectHeight, xAxis, yAxis,
    } = extractCanvasProperties(canvas);

    // Clear canvas
    context.clearRect(0, 0, width, rectHeight);

    context.beginPath();
    drawAxes(context, width, rectHeight, xAxis, yAxis);
    context.stroke();

    context.beginPath();
    drawCircle(context, xAxis, yAxis);
    context.stroke();

    // context.beginPath();
    // drawSine(context, width, xAxis, yAxis);
    // context.stroke();

    context.beginPath();
    drawSineArrow(context, width, xAxis, yAxis);
    context.stroke();
  };

  const draw = () => {
    seconds -= 0.005;
    t = seconds * Math.PI;
    if (canvasRef && canvasRef.current) {
      drawSquareWave(canvasRef.current);
    }
    requestAnimationFrame(draw);
  };

  const initializeAnimation = () => {
    const scale = window.devicePixelRatio;
    const width = document.documentElement.clientWidth;

    if (canvasRef.current) {
      canvasRef.current.width = Math.floor(scale * width);
      canvasRef.current.height = Math.floor(scale * height);
      const context = canvasRef.current.getContext('2d');
      context.lineJoin = 'round';
      context.save();
    }
  };

  useEffect(() => {
    initializeAnimation();
    draw();
    window.addEventListener('resize', () => {
      initializeAnimation();
    });
  }, []);

  return (
    <Holder height={height}>
      <Canvas height={height} ref={canvasRef} />
    </Holder>
  );
}

SquareWave.propTypes = {
  height: PropTypes.number.isRequired,
};

export default SquareWave;
