import React from 'react';
import PropTypes from 'prop-types';

function QuoteIcon({
  color,
  size,
  marginRight,
  marginBottom,
  marginTop,
  marginLeft,
}) {
  const sizeString = `${size}px`;
  const svg = (
    <svg
      width={sizeString}
      height={sizeString}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
      }}
    >
      <path
        fill={color}
        d="M24 15a7 7 0 0 0-1 .08 9 9 0 0 1 4.59-7.33c.85-.48 1.81-1.23 1.25-2.5a1.88 1.88 0 0 0-2.54-1.12l-.21.08C20.65 6.24 16 12 17 22c.37 4 3.13 7 7 7a7 7 0 0 0 0-14zM8 15a7 7 0 0 0-1 .08 9 9 0 0 1 4.59-7.33c.85-.48 1.81-1.23 1.25-2.5a1.88 1.88 0 0 0-2.54-1.12l-.21.08C4.65 6.24 0 12 1 22c.37 4 3.13 7 7 7a7 7 0 1 0 0-14z"
        className="color7dccfc svgShape"
      />
    </svg>
  );
  return svg;
}

QuoteIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
};

QuoteIcon.defaultProps = {
  marginRight: 0,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
};

export default QuoteIcon;
