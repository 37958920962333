import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  height: ${(props) => `${props.height}px`};
  width: 100%;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: ${(props) => `${props.height}px`};
  background-color:transparent;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const extractCanvasProperties = (canvas) => {
  const { height, width } = canvas;
  const xAxis = Math.floor(height / 2);
  const yAxis = Math.floor(width / 6);
  const context = canvas.getContext('2d');
  return {
    height, width, xAxis, yAxis, context,
  };
};

let seconds = 0;
let t = 0;

function StandingWave({ height }) {
  const canvasRef = useRef(null);

  const drawXAxis = (context, width, xAxis) => {
    context.strokeStyle = 'rgba(255,255,255,0.5)';
    context.fillStyle = 'rgba(255,255,255,0.5)';
    context.lineWidth = 3;

    context.moveTo(0, xAxis);
    context.lineTo(width, xAxis);
  };

  function drawFundamental(context, xAxis, width) {
    context.strokeStyle = 'rgba(255,255,255,0.8)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 6;

    context.moveTo(0, xAxis);
    context.quadraticCurveTo(width / 2, xAxis - height * 0.9 * Math.sin(t), width, xAxis);
    context.stroke();
  }

  function drawFirstPartial(context, xAxis, width) {
    context.strokeStyle = 'rgba(255,255,255,0.6)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 5;

    context.moveTo(0, xAxis);
    context.quadraticCurveTo(
      0.25 * width,
      xAxis - height * 0.7 * Math.sin(t * 2),
      width / 2,
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(0.75 * width, xAxis + height * 0.8 * Math.sin(t * 2), width, xAxis);
    context.stroke();
  }

  function drawSecondPartial(context, xAxis, width) {
    context.strokeStyle = 'rgba(255,255,255,0.4)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 4;

    context.moveTo(0, xAxis);
    context.quadraticCurveTo(
      0.167 * width,
      xAxis - height * 0.7 * Math.sin(t * 3),
      width / 3,
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.5 * width,
      xAxis + height * 0.6 * Math.sin(t * 3),
      (2 * width) / 3,
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.833 * width,
      xAxis - height * 0.5 * Math.sin(t * 3),
      width,
      xAxis,
    );
    context.stroke();
  }

  function drawThirdPartial(context, xAxis, width) {
    context.strokeStyle = 'rgba(255,255,255,0.2)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 4;

    context.moveTo(0, xAxis);

    context.quadraticCurveTo(
      0.125 * width,
      xAxis - height * 0.4 * Math.sin(t * 4),
      width / 4,
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.375 * width,
      xAxis + height * 0.4 * Math.sin(t * 4),
      width / 2,
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.625 * width,
      xAxis - height * 0.4 * Math.sin(t * 4),
      (3 * width) / 4,
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.875 * width,
      xAxis + height * 0.4 * Math.sin(t * 4),
      width,
      xAxis,
    );
    context.stroke();
  }

  function drawFourthPartial(context, xAxis, width) {
    context.strokeStyle = 'rgba(255,255,255,0.075)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 2;

    context.moveTo(0, xAxis);

    context.quadraticCurveTo(
      0.1 * width,
      xAxis - height * 0.4 * Math.sin(t * 5),
      width * (1 / 5),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.3 * width,
      xAxis + height * 0.4 * Math.sin(t * 5),
      width * (2 / 5),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.5 * width,
      xAxis - height * 0.4 * Math.sin(t * 5),
      width * (3 / 5),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.7 * width,
      xAxis + height * 0.4 * Math.sin(t * 5),
      width * (4 / 5),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.9 * width,
      xAxis - height * 0.4 * Math.sin(t * 5),
      width * (5 / 5),
      xAxis,
    );
    context.stroke();
  }

  function drawFifthPartial(context, xAxis, width) {
    context.strokeStyle = 'rgba(255,255,255,0.065)';
    context.fillStyle = '#e6e6e6';
    context.lineWidth = 2;

    context.moveTo(0, xAxis);

    context.quadraticCurveTo(
      0.083 * width,
      xAxis - height * 0.35 * Math.sin(t * 6),
      width * (1 / 6),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.25 * width,
      xAxis + height * 0.35 * Math.sin(t * 6),
      width * (2 / 6),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.416 * width,
      xAxis - height * 0.35 * Math.sin(t * 6),
      width * (3 / 6),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.583 * width,
      xAxis + height * 0.35 * Math.sin(t * 6),
      width * (4 / 6),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.75 * width,
      xAxis - height * 0.35 * Math.sin(t * 6),
      width * (5 / 6),
      xAxis,
    );
    context.stroke();

    context.quadraticCurveTo(
      0.917 * width,
      xAxis - height * 0.35 * Math.sin(t * 6),
      width * (6 / 6),
      xAxis,
    );
    context.stroke();
  }

  const drawStandingWave = (canvas) => {
    const {
      context, width, height: rectHeight, xAxis,
    } = extractCanvasProperties(canvas);

    // Clear canvas
    context.clearRect(0, 0, width, rectHeight);

    // Draw x-axis
    context.beginPath();
    drawXAxis(context, width, xAxis);
    context.stroke();

    // Draw Fundamental
    context.beginPath();
    drawFundamental(context, xAxis, width);
    context.stroke();

    // Draw 1st Partial
    context.beginPath();
    drawFirstPartial(context, xAxis, width);
    context.stroke();

    // Draw 2nd Partial
    context.beginPath();
    drawSecondPartial(context, xAxis, width);
    context.stroke();

    // Draw 3rd Partial
    context.beginPath();
    drawThirdPartial(context, xAxis, width);
    context.stroke();

    // Draw 4th Partial
    context.beginPath();
    drawFourthPartial(context, xAxis, width);
    context.stroke();

    // Draw 5th Partial
    context.beginPath();
    drawFifthPartial(context, xAxis, width);
    context.stroke();
  };

  const draw = () => {
    seconds -= 0.004;
    t = seconds * Math.PI;
    if (canvasRef && canvasRef.current) {
      drawStandingWave(canvasRef.current);
    }
    requestAnimationFrame(draw);
  };

  const initializeAnimation = () => {
    const scale = window.devicePixelRatio;
    const width = document.documentElement.clientWidth;

    if (canvasRef.current) {
      canvasRef.current.width = Math.floor(scale * width);
      canvasRef.current.height = Math.floor(scale * height);
      const context = canvasRef.current.getContext('2d');
      context.lineJoin = 'round';
      context.save();
    }
  };

  useEffect(() => {
    initializeAnimation();
    draw();
    window.addEventListener('resize', () => {
      initializeAnimation();
    });
  }, []);

  return (
    <Holder height={height}>
      <Canvas height={height} ref={canvasRef} />
    </Holder>
  );
}

StandingWave.propTypes = {
  height: PropTypes.number.isRequired,
};

export default StandingWave;
