import React from 'react';
import PropTypes from 'prop-types';

function MediumIcon({
  color,
  size,
  marginRight,
  marginBottom,
  marginTop,
  marginLeft,
}) {
  const sizeString = `${size}px`;
  const svg = (
    <svg
      width={sizeString}
      height={sizeString}
      viewBox="0 0 256 256"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        marginLeft: marginLeft || 0,
        marginRight: marginRight || 0,
        marginTop: marginTop || 0,
        marginBottom: marginBottom || 0,
      }}
    >
      <path
        fill={color}
        d="M136,128c0,37.49512-28.71,68-64,68S8,165.49514,8,128,36.71,60,72,60,136,90.5049,136,128Zm48-64c-5.68262,0-16.39941,2.76074-24.32373,21.251C154.72607,96.8008,152,111.98342,152,128s2.72607,31.19922,7.67627,42.749C167.60059,189.23928,178.31738,192,184,192s16.39941-2.76074,24.32373-21.251C213.27393,159.19924,216,144.01662,216,128s-2.72607-31.19922-7.67627-42.749C200.39941,66.76076,189.68262,64,184,64Zm56,0a8.00039,8.00039,0,0,0-8,8V184a8,8,0,0,0,16,0V72A8.00039,8.00039,0,0,0,240,64Z"
      />
    </svg>
  );
  return svg;
}

MediumIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
};

MediumIcon.defaultProps = {
  marginRight: 0,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
};

export default MediumIcon;
