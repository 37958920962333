import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  background-color:transparent;
  margin-left:auto;
  margin-right:auto;
`;

const LissajousCanvas1 = styled.canvas`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  background-color:transparent;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const LissajousCanvas2 = styled(LissajousCanvas1)`
  margin-top: ${(props) => `${-1 * props.size}px`};;
  background-color: transparent;
`;

const extractCanvasProperties = (canvas) => {
  const { height, width } = canvas;
  const xAxis = Math.floor(height / 2);
  const yAxis = Math.floor(width / 6);
  const context = canvas.getContext('2d');
  return {
    height, width, xAxis, yAxis, context,
  };
};

const squareNumber = 7;
let seconds = 0;
let t = 0;

function Lissajous({ size }) {
  const canvas1Ref = useRef(null);
  const canvas2Ref = useRef(null);

  const drawLissajousLines = (context, i, j, x, y, xDim, yDim) => {
    const midX = xDim / 2;
    const midY = yDim / 2;

    const pointX = Math.cos(t * i) * midX + midX;
    const pointY = Math.sin(t * j) * midY + midY;

    context.beginPath();
    context.arc(pointX + x, pointY + y, 1.5, 0, 2 * Math.PI, false);
    context.stroke();
    context.fill();
    context.closePath();
  };

  const drawEdgeCircle = (context, i, x, y, xDim, yDim, isVertical, width, height) => {
    if (i === 0) {
      return;
    }

    const time = t * i;

    context.beginPath();
    const midX = xDim / 2;
    const midY = yDim / 2;

    context.arc(midX + x, midY + y, midX, 0, 2 * Math.PI, false);
    context.stroke();
    context.closePath();

    const pointX = Math.cos(time) * midX + midX;
    const pointY = Math.sin(time) * midY + midY;

    context.beginPath();
    context.arc(pointX + x, pointY + y, 4, 0, 2 * Math.PI, false);
    context.stroke();
    context.fill();
    context.closePath();

    context.lineWidth = 1;
    context.globalAlpha = 1;
    if (isVertical === true) {
      context.beginPath();
      context.moveTo(0, pointY + y);
      context.lineTo(width - 10, pointY + y);
      context.stroke();
      context.fill();
      context.closePath();
      // for (let j = 3; j <= width - 15; j += 10) {
      //   context.beginPath();
      //   context.arc(j, pointY+y, 0.5, 0, 2*Math.PI, false);
      //   context.stroke();
      //   context.fill();
      //   context.closePath();
      // }
    } else {
      context.beginPath();
      context.moveTo(pointX + x, 0);
      context.lineTo(pointX + x, height - 10);
      context.stroke();
      context.fill();
      context.closePath();
      // for (let j = 3; j <= height - 15; j += 10) {
      //   context.beginPath();
      //   context.arc(pointX+x, j, 0.5, 0, 2*Math.PI, false);
      //   context.stroke();
      //   context.fill();
      //   context.closePath();
      // }
    }
    context.globalAlpha = 1.0;
    context.lineWidth = 3;
  };

  const drawLissajousPoint = (context, i, j, x, y, xDim, yDim) => {
    const midX = xDim / 2;
    const midY = yDim / 2;

    const pointX = Math.cos(t * i) * midX + midX;
    const pointY = Math.sin(t * j) * midY + midY;

    context.beginPath();
    context.fillStyle = 'white';
    context.arc(pointX + x, pointY + y, 4, 0, 2 * Math.PI, false);
    context.stroke();
    context.fill();
    context.closePath();
  };

  const drawTouchings = (canvas) => {
    const {
      context, height, width,
    } = extractCanvasProperties(canvas);

    // Clear the canvas
    context.clearRect(0, 0, width, height);

    // Draw the axes in their own path
    context.beginPath();
    context.stroke();

    // Set styles for animated graphics
    context.strokeStyle = 'rgba(255,255,255,1)';
    context.fillStyle = 'rgba(255,255,255,1)';
    context.lineWidth = 3.5;
    context.globalAlpha = 0.7;

    const edgePadding = 5;
    const innerPadding = 20;
    const drawableHeight = height - (squareNumber * innerPadding) - (edgePadding);
    const drawableWidth = width - (squareNumber * innerPadding) - (edgePadding);

    const xSquareDim = Math.floor(drawableHeight / squareNumber);
    const ySquareDim = Math.floor(drawableWidth / squareNumber);

    // Draw the sine curve at time draw.t, as well as the circle.
    for (let i = 0; i < squareNumber; i += 1) {
      const isNotTop = i > 0;

      const yOffset = ySquareDim * i + edgePadding + (isNotTop * innerPadding * i);
      const xOffset = xSquareDim * i + edgePadding + (isNotTop * innerPadding * i);
      context.beginPath();

      // Vertical
      drawEdgeCircle(
        context,
        i,
        edgePadding,
        yOffset,
        xSquareDim,
        ySquareDim,
        true,
        width,
        height,
      );

      // Horizontal
      drawEdgeCircle(
        context,
        i,
        xOffset,
        edgePadding,
        xSquareDim,
        ySquareDim,
        false,
        width,
        height,
      );
    }

    // Lissajous
    for (let i = 1; i < squareNumber; i += 1) {
      for (let j = 1; j < squareNumber; j += 1) {
        const isNotTop = i > 0;
        const yOffset = ySquareDim * j + edgePadding + (isNotTop * innerPadding * j);
        const xOffset = xSquareDim * i + edgePadding + (isNotTop * innerPadding * i);
        drawLissajousPoint(context, i, j, xOffset, yOffset, xSquareDim, ySquareDim);
      }
    }

    // Restore original styles
    context.restore();
  };

  const drawLissajousPlots = (canvas) => {
    const {
      context, height, width,
    } = extractCanvasProperties(canvas);
    // Draw the axes in their own path
    context.beginPath();

    // Set styles for animated graphics
    context.strokeStyle = 'rgba(255,255,255,0.7)';
    context.fillStyle = 'rgba(255,255,255,1)';
    context.lineWidth = 0.5;
    context.globalAlpha = 0.2;

    const edgePadding = 5;
    const innerPadding = 20;
    const drawableHeight = height - (squareNumber * innerPadding) - (edgePadding);
    const drawableWidth = width - (squareNumber * innerPadding) - (edgePadding);

    const xSquareDim = Math.floor(drawableHeight / squareNumber);
    const ySquareDim = Math.floor(drawableWidth / squareNumber);

    // Lissajous
    for (let i = 1; i < squareNumber; i += 1) {
      for (let j = 1; j < squareNumber; j += 1) {
        const isNotTop = i > 0;
        const yOffset = ySquareDim * j + edgePadding + (isNotTop * innerPadding * j);
        const xOffset = xSquareDim * i + edgePadding + (isNotTop * innerPadding * i);
        drawLissajousLines(context, i, j, xOffset, yOffset, xSquareDim, ySquareDim);
      }
    }

    context.restore();
  };

  const draw = () => {
    seconds -= 0.0025;
    t = seconds * Math.PI;
    if (canvas1Ref && canvas1Ref.current) {
      drawTouchings(canvas1Ref.current);
    }
    if (canvas2Ref && canvas2Ref.current) {
      drawLissajousPlots(canvas2Ref.current);
    }
    requestAnimationFrame(draw);
  };

  const initializeAnimation = () => {
    const scale = window.devicePixelRatio;

    if (canvas1Ref.current) {
      canvas1Ref.current.width = Math.floor(scale * size);
      canvas1Ref.current.height = Math.floor(scale * size);
      const context1 = canvas1Ref.current.getContext('2d');
      context1.lineJoin = 'round';
      context1.save();
    }

    if (canvas2Ref.current) {
      canvas2Ref.current.width = Math.floor(scale * size);
      canvas2Ref.current.height = Math.floor(scale * size);
      const context2 = canvas2Ref.current.getContext('2d');
      context2.lineJoin = 'round';
      context2.save();
    }

    draw();
  };

  useEffect(() => {
    initializeAnimation();
  }, [size]);

  return (
    <Holder size={size}>
      <LissajousCanvas1 size={size} ref={canvas1Ref} />
      <LissajousCanvas2 size={size} ref={canvas2Ref} />
    </Holder>
  );
}

Lissajous.propTypes = {
  size: PropTypes.number.isRequired,
};

export default Lissajous;
